<template>
  <transition-group
    v-show="$store.state._menus.length"
    class="menu"
    :name="
      $store.state._menus.length && $store.state._menus[$store.state._menus.length - 1].alignRight
        ? 'slide-right'
        : 'slide-left'
    "
    tag="div"
  >
    <div v-for="(menu, index) in menus" :key="JSON.stringify(menu) + index" class="menu__content">
      <div ref="background" class="menu__background" @click="close(index)"></div>
      <div
        ref="menu"
        class="menu__body"
        :class="{ 'menu__body--left': menu.alignLeft, 'menu__body--right': menu.alignRight }"
      >
        <component :is="menu.component" :key="index" v-bind="menu.options" @close="close(index)"></component>
      </div>
    </div>
  </transition-group>
</template>

<script>
export default {
  name: "MenuComponent",
  methods: {
    close(i) {
      return this.$store.state._menus.splice(i, 1);
    },
  },
  mounted() {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        this.close(this.$store.state._menus.length - 1);
      }
    });
  },
  beforeDestroy() {
    document.removeEventListener("keydown", () => {});
  },
  computed: {
    menus() {
      return this.$store.state._menus;
    },
  },
  // updated() {
  //   if (this.$refs.menu && this.$refs.menu.length) {
  //     setTimeout(() => {
  //       this.$refs.menu.forEach((m, i) => {
  //         const bounds = m.getBoundingClientRect();
  //         this.$refs.background[i].style = `width:${bounds.width}px`;
  //       });
  //     }, 300);
  //   }
  // },
  watch: {
    "$store.state._menus.length"(val) {
      if (val > 0) {
        document.body.classList.add("no-overflow");
      } else {
        document.body.classList.remove("no-overflow");
      }
    },
    "$route.name"() {
      this.$store.state._menus = [];
    },
    "$route.params"() {
      this.$store.state._menus = [];
    },
  },
};
</script>

<style lang="stylus">
.slide-right {
  &-enter-active, &-leave-active {
    transition var(--transition)
    transform translateX(0)
  }

  &-enter, &-leave-to {
    transform translateX(100%)
  }
}

.slide-left {
  &-enter-active, &-leave-active {
    transition var(--transition)
    transform translateX(0)
  }

  &-enter, &-leave-to {
    transform translateX(-100%)
  }
}

.no-overflow {
  overflow hidden
}

.menu {
  fixed left top
  width 100%
  height 100%
  z-index 999
  overflow hidden
  background var(--black-o5)

  &__content {
    width 100%
    height 100%
    position relative
    display none
    align-items flex-start
    justify-content center
    overflow-y auto

    &:last-child {
      display flex
    }
  }

  &__background {
    fixed left top
    height 100vh
    width 100vw
    z-index 0
    cursor pointer
  }

  &__body {
    display flex
    justify-content: left;
    width 100%

    &--left {
      justify-content left
    }

    &--right {
      justify-content right
    }
  }
}
</style>
